// 공통
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  
    html,
    body,
    #root {
      width: 100vw;
      height: 100vh;
      min-width: 1280px;
      font-size: 100%;
      font-family: "Noto sans KR", "Dotum", "돋움", sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #222;
      letter-spacing: -0.15px;
    }
  
    a,
    a:active,
    a:hover,
    a:visited {
      color: inherit;
      text-decoration: none;
    }
  
    ul,
    li {
      list-style: none;
    }
    p {
      margin: 0;
      padding: 0;
    }
  
    button,
    input,
    select,
    textarea {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  
    button {
      border: 0;
    }
  
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bdbdbd;
      opacity: 1;
      /* Firefox */
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bdbdbd;
    }
  
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bdbdbd;
    }
  
    img {
      max-width: 100%;
    }
  
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
    }
  
    input::-ms-clear {
      display: none;
    }
  
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  
    select {
      border-radius: 0;
    }
  
    select::-ms-expand {
      display: none;
    }
    body {
        margin: 0;
        padding: 0;
        font-family: NotoSansRegular;
        height: 100%;
      }
      
      #root {
        height: 100%;
      }
      
      
      .ant-table-thead > tr > th {
        /* text-align: center; */
        background-color: #cee2f1;
        /* border: 1px solid #c2c2c0;
        font-weight: 530; */
      }
      
      .table-column-text {
        word-break: break-all;
      }
      
      .table-column-center {
        text-align: center !important;
        font-size: 13px;
      }
      
      .table-column-left {
        text-align: left !important;
      }
      
      .table-column-content {
        text-align: left;
        border: 1px solid #d1d1d1;
        background-color: #f5f5f5;
        margin-bottom: 10px;
        padding: 10px 15px 10px 20px;
        word-break: keep-all;
      }

    .ant-table-thead>tr>th,
    .column-center {
      text-align: center;
    }
 
    // .search-input .ant-btn-primary {
    //   border-color: #000e55;
    //   background: #000e55;
    // }
    .ant-table-tbody>tr>td{
      padding: 12px;
    }
    .ant-table-pagination{
      padding-right:20px;
    }
    .ant-table-pagination-right{
      justify-content: center!important;
    }
    .rowColor{
      background-color: #888;
    }
  
/*왼쪽메뉴 스타일*/
.ant-layout-sider-children {
    background: #000e55 !important;
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color:#c8d1ff!important;
    background: #000e55 !important;
  }
  
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #c8d1ff !important;
  }
  
  .ant-menu-dark .ant-menu-submenu-selected{
    color: #000e55 !important;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #eda858 !important;
    color: #000e55 !important;
  }
  
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > span > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ant-menu-dark
    .ant-menu-item-selected
    > span
    > a:hover
    .ant-table-thead
    > tr
    > th {
    color: #c8d1ff !important;
  }
  .ant-menu-item .ant-menu-item-only-child{
    color: #c8d1ff !important;
  }
  
  
   .ant-menu-dark .ant-menu-item > span> .ant-menu-item-selected  {
    color: #c8d1ff !important;
  }
  
  
  .ant-menu-dark .ant-menu-item > span > a:hover{
    color: #c8d1ff !important;
  }
  
  
  /*table 상단 스타일*/
  .ant-table-thead > tr > th {
    background: #000e55;
  }
  
  .ant-table-thead > tr > th {
    color: #fff;
  }
  
  /*조회버튼*/
  
  
  .ant-btn{  
    padding: 4px 10px!important;
  }
  
  .ant-btn-primary {
    background-color: #c8d1ff;
    border-color: #c8d1ff;
    color: #000e55;
  }
  
  .ant-btn-primary:hover {
    background-color: #c8d1ff;
    color: #000e55;
    border-color: #c8d1ff;
  }
  
  /*버튼*/
  
  .ant-btn:hover,
  .ant-btn:focus {
    /* background-color: #50e5f1; */
    background-color: #c8d1ff;
    color: #000e55;
    border-color: #c8d1ff;
  }
  
  /*input*/
  .ant-picker:hover, .ant-picker-focused{
    border-color:#000e55;
  }
  
  .ant-input-affix-wrapper:hover{
    border-color:#000e55;
  }

  }